/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ol: "ol",
    li: "li",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "You and we agree to use the Notification Email Address for communication between you and us."), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "📧 ", React.createElement(_components.strong, null, "Our Notification Email Address is: hello@signatu.com")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "📧 ", React.createElement(_components.strong, null, "Your sign up Email Address count as your Notification Email Address.")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "You are solely responsible for:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "ensuring that the Notification Email Address is current and valid, and"), "\n", React.createElement(_components.li, null, "the consequences of a failed notification to you if your Notification Email Address is not current and/or valid."), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "You agree that all communications and notices made or given pursuant to this Agreement must be in the English language."), "\n"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
